<template>
  <v-card
    :loading="loading"
    class="term-item bg-grey-gradient"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div class="click-area height-100" @click="$emit('click', value)">
      <v-card-text class="pa-0 height-100">
        <v-row class=" domain-row  height-100">
            <v-avatar v-if="image" left>
              <img :src="image" />
            </v-avatar>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="simple-text domain-name" v-bind="attrs" v-on="on">
                  {{ value.name }}
                </span>
              </template>
              <span class="tooltip-text"
                ><strong>{{ value.name }}</strong
                >: {{ value.description }}</span
              >
            </v-tooltip>
        </v-row>
      </v-card-text>
    </div>

    <!-- <v-card-actions
      class="card-actions-bottom pa-0"
      v-if="value.tags && value.tags.length"
    >
      <v-slide-group
        class="ma-0 pa-0 slider-some"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click.prevent.stop="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import { CASE_STUDIES_SLIDES_IMAGES } from '../../../../constants/defaults/SlidesImages';
import { FormatterHelper } from "../../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image: function () {
      return this.value && this.value.slide_image_key
        ? this.slideImages.find((el) => el.value === this.value.slide_image_key)
            .img
        : undefined;
    },
  },
  data: () => {
    return {
      cutFormatter: FormatterHelper.cutString,
      formatter: FormatterHelper,
      loading: false,
      show: false,

      slideImages: CASE_STUDIES_SLIDES_IMAGES
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.term-item {
  z-index: 2;
  overflow: hidden;
  // width: 200px;

  height: px;
  transition: all 0.2s;
  margin: 4px;

  &:hover {
    transform: scale(1.05);
  }

  .term-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .domain-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 100%;
    .domain-name {
      padding: 12px;
      font-size: 16px;
      max-width: 170px;
    }
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform: translate(-100%);
      background: radial-gradient(
        circle,
        rgba(205, 48, 59, 0.5) 0%,
        rgba(205, 48, 59, 0.6) 50%,
        rgba(205, 48, 59, 0.6) 63%
      );
      transition: all 0.3s;
    }

    &.active::after {
      transform: translate(0%);
    }
  }
}
</style>