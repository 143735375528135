<template>
  <AView
    :value="value"
    :actions="actions"
    :active="experienceNote.id === activeExperienceNoteId"
    :name="experienceNote.name"
    ref="experienceNote-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, experienceNote })"
  >
    <template v-slot:content="{}">
      <ExperienceNoteDetailsVue
        :value="experienceNote"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value, experienceNote })"
      ></ExperienceNoteDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import ExperienceNoteDetailsVue from "../../../../../../components/wad/organisms/knowledgeBase/experienceNotes/ExperienceNoteDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import { EXPERIENCE_NOTE_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ExperienceNoteDetailsVue,
  },
  data: () => ({
    experienceNote: {},
  }),
  computed: {
    ...mapState("ExperienceNotesStore", [
      "activeExperienceNoteId",
      "displayedExperienceNotes",
    ]),
    ...mapGetters("ExperienceNotesStore", ["experienceNoteById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          EXPERIENCE_NOTE_BUTTONS.GENERAL_EDITOR_MAIN,
          EXPERIENCE_NOTE_BUTTONS.SETTINGS_EDITOR_MAIN,
        ],
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("ExperienceNotesStore/GetExperienceNote", {
      id: this.value.relation.params.experienceNoteId,
    });

    this.experienceNote = this.experienceNoteById(
      this.value.relation.params.experienceNoteId
    );
  },
  methods: {
    onHover() {
      this.$store.commit(
        "ExperienceNotesStore/setActiveId",
        this.value.relation.params.experienceNoteId
      );
    },
  },
  watch: {
    displayedExperienceNotes: {
      async handler(newVal) {
        if (newVal) {
          this.experienceNote = this.experienceNoteById(
            this.value.relation.params.experienceNoteId
          );
        }
      },
      deep: true,
    },
  },
};
</script>