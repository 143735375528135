export const CASE_STUDIES_SLIDES_IMAGES = [{
        value: "Google Shape;210;p39",
        name: "Google Shape;210;p39",
        img: "/case-studies-slides/210.png",
    },
    {
        value: "Google Shape;211;p39",
        name: "Google Shape;211;p39",
        img: "/case-studies-slides/211.png",
    },
    {
        value: "Google Shape;212;p39",
        name: "Google Shape;212;p39",
        img: "/case-studies-slides/212.png",
    },
    {
        value: "Google Shape;213;p39",
        name: "Google Shape;213;p39",
        img: "/case-studies-slides/213.png",
    },
    {
        value: "Google Shape;214;p39",
        name: "Google Shape;214;p39",
        img: "/case-studies-slides/214.png",
    },
    {
        value: "Google Shape;215;p39",
        name: "Google Shape;215;p39",
        img: "/case-studies-slides/215.png",
    },
    {
        value: "Google Shape;216;p39",
        name: "Google Shape;216;p39",
        img: "/case-studies-slides/216.png",
    },
    {
        value: "Google Shape;217;p39",
        name: "Google Shape;217;p39",
        img: "/case-studies-slides/217.png",
    },
    {
        value: "Google Shape;218;p39",
        name: "Google Shape;218;p39",
        img: "/case-studies-slides/218.png",
    },
    {
        value: "Google Shape;219;p39",
        name: "Google Shape;219;p39",
        img: "/case-studies-slides/219.png",
    },
    {
        value: "Google Shape;220;p39",
        name: "Google Shape;220;p39",
        img: "/case-studies-slides/220.png",
    },
    {
        value: "Google Shape;221;p39",
        name: "Google Shape;221;p39",
        img: "/case-studies-slides/221.png",
    },
    {
        value: "Google Shape;222;p39",
        name: "Google Shape;222;p39",
        img: "/case-studies-slides/222.png",
    },
    {
        value: "Google Shape;223;p39",
        name: "Google Shape;223;p39",
        img: "/case-studies-slides/223.png",
    },
    {
        value: "Google Shape;224;p39",
        name: "Google Shape;224;p39",
        img: "/case-studies-slides/224.png",
    },
];