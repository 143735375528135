<template>
  <v-card
    :loading="loading"
    class="term-item bg-grey-gradient"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div class="click-area" @click="$emit('click', value)">
      <v-card-text class="pa-0">
        <v-row class="justify-space-between">
          <v-col cols="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p class="simple-text" v-bind="attrs" v-on="on">
                  {{ cutFormatter(value.name, 20) }}
                </p>
              </template>
              <span class="tooltip-text"
                ><strong>{{ value.name }}</strong
                >: {{ value.description }}</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-card-actions
      class="card-actions-bottom pa-2 term-tags"
      v-if="value.tags && value.tags.length"
    >
      <v-chip
        v-for="item in value.tags.slice(0,3)"
        :key="item.tag.id"
        class="mb-2 tag-chip"
        :color="item.tag.color"
        @click.prevent.stop="toggle"
        label
        text-color="white"
      >
        <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
        {{ item.tag.name }}
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      cutFormatter: FormatterHelper.cutString,
      formatter: FormatterHelper,
      loading: false,
      show: false,
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.term-item {
  z-index: 2;
  overflow: hidden;
  width: 180px;
  height: 176px;
  transition: all 0.2s;
  margin: 8px;

  &:hover {
    transform: scale(1.05);
  }

  .term-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .term-tags{
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .tag-chip{
      width: 100%;
    }
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform: translate(-100%);
      background: radial-gradient(
        circle,
        rgba(205, 48, 59, 0.5) 0%,
        rgba(205, 48, 59, 0.6) 50%,
        rgba(205, 48, 59, 0.6) 63%
      );
      transition: all 0.3s;
    }

    &.active::after {
      transform: translate(0%);
    }


  }
}
</style>