<template>
  <v-card class="transparent mt-4 pa-2 elevation-0">
    <v-card-title>
      {{ String(value.name).toUpperCase() }}
    </v-card-title>
    <!-- Domains -->
    <FormSection
      class="mt-0"
      :label="'Business Domains'"
      :editable="false"
      underline
      v-if="value.domains && value.domains.length"
    ></FormSection>
    <v-slide-group class="pa-2" center-active show-arrows>
      <v-slide-item v-for="item in value.domains" :key="item.id">
        <BusinessDomainItemSmall
          @click="() => onDomainClick(item.domain)"
          :value="item.domain"
        ></BusinessDomainItemSmall>
      </v-slide-item>
    </v-slide-group>
    <v-card-text>
      <v-slide-group
        v-model="tagIndex"
        class="my-4 px-0 mx-0"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="mr-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <h3 class="my-2">DESCRIPTION:</h3>
      <p class="simple-text" v-html="value.description"></p>

      <h3 class="my-2" v-if="value.solved_tasks">Solved Tasks:</h3>
      <p
        class="simple-text"
        v-if="value.solved_tasks"
        v-html="value.solved_tasks"
      ></p>

      <v-row>
        <v-col class="flex-col-300 pa-0" v-if="value.pros">
          <h3 class="my-2">Advantages:</h3>
          <p class="simple-text" v-html="value.pros"></p>
        </v-col>
        <v-col class="flex-col-300 pa-0" v-if="value.cons">
          <h3 class="my-2">Disadvantages:</h3>
          <p class="simple-text" v-html="value.cons"></p>
        </v-col>
      </v-row>

      <!-- Linked Terms -->
      <FormSection
        :label="'Linked Terms'"
        :icon="'mdi-book-alphabet'"
        underline
        right
        :actions="actions"
        :editable="!!actions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <v-slide-group class="pa-2" center-active show-arrows>
        <v-slide-item v-for="item in value.terms" :key="item.term.id">
          <GlossaryTermItemSmall
            @click="() => $emit('onTermClick', item.term)"
            :value="item.term"
          ></GlossaryTermItemSmall>
        </v-slide-item>
      </v-slide-group>

      <!-- Useful Links -->
      <FormSection
        underline
        right
        class="mt-0"
        :label="'Useful Links'"
        :icon="'mdi-link-variant'"
        :actions="linkActions"
        :editable="!!linkActions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <LinksListVue
        v-if="value.links && value.links.length > 0"
        :value="value.links"
      ></LinksListVue>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import {
  EXPERIENCE_NOTE_BUTTONS,
  GLOSSARY_BUTTONS,
} from "../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";
import FormSection from "../../../atoms/common/FormSection.vue";
import GlossaryTermItemSmall from "../../glossary/GlossaryTermItemSmall.vue";
import LinksListVue from "../../knowledgeBase/links/LinksList.vue";
import BusinessDomainItemSmall from "../businessDomains/BusinessDomainItemSmall.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    FormSection,
    LinksListVue,
    BusinessDomainItemSmall,
    GlossaryTermItemSmall,
  },
  data: () => ({
    tagIndex: null,
  }),
  computed: {
    linkActions: function () {
      return PermissionsHelper.getActions(
        EXPERIENCE_NOTE_BUTTONS.GENERAL_EDITOR,
        this.permissions
      );
    },
    actions: function () {
      return PermissionsHelper.getActions(
        [GLOSSARY_BUTTONS.GENERAL_EDITOR, GLOSSARY_BUTTONS.LIST],
        this.permissions
      );
    },
  },
  async created() {},
  methods: {},
  watch: {},
};
</script>